'use client';

import { PlaneIcon } from "icons/components";
import React, { RefObject, useEffect, useRef } from "react";
import { PathSection } from "./paper-plane.styles";
import { PaperPlaneTypes, LayerDashedPathTypes } from "./types";

const TOP_OFFSET = 450;
const PLANE_WIDTH = 88;
const SVG_HEIGHT = 3800;
const DEFAULT_PATH = "M579.478 1C586.906 12.7814 597.927 48.4753 582.587 97C567.248 145.525 383.951 397.608 533.942 513.444C579.477 548.61 594.678 578.371 590.134 594.085C582.089 621.905 564.325 630.955 545.274 645.318C434.311 728.976 254.824 788.675 281.085 1145.45C288.587 1247.36 114.248 1167.09 32.0474 1277C-7.52444 1329.91 -20.371 1452.09 66.6933 1505.63C153.758 1559.16 453.498 1629.72 421 1709C388.501 1788.28 338.578 1807.37 320.016 1830.59C304.213 1850.37 290.459 1879.1 281.085 1901C275.735 1913.5 150.193 2131.79 308.814 2233C467.435 2334.21 345.703 2528.97 327.259 2573C308.814 2617.03 333.676 2669.43 341 2693C363.969 2766.91 347.913 2721.54 358.546 2746.61C372.27 2774.37 407.533 2819.2 413.194 2825.62C453.013 2870.83 551.304 2935.69 486.152 3101C434.03 3233.25 351.667 3361.44 317 3409C307.345 3425.14 276.641 3464.3 227.163 3481C163.149 3502.61 77.4584 3559.26 77.4584 3636.81C77.4584 3713.78 92.5686 3767.83 167.483 3799.17";

function getScrollPercentage(elementRef: RefObject<HTMLDivElement>) {
  const element = elementRef.current;
  if (!element) return 0;

  const scrollTop = window.scrollY;
  const scrollLenght = element.offsetHeight > window.innerHeight ? 
                      element.offsetHeight - window.innerHeight + element.offsetTop : 
                      window.innerHeight - element.offsetHeight;

  return scrollTop / scrollLenght;
}

const LayerDashedPath = ({ path }: LayerDashedPathTypes) => {
  return (
    <path
      stroke="#FBF7F2"
      strokeDasharray='8 9'
      strokeDashoffset='0'
      strokeWidth='2.5'
      style={{ fill: 'none', fillRule: 'evenodd', strokeLinejoin: 'round' }}
      d={path}
    />
  );
}

export const PaperPlane = ({ mainContainerRef, ...props }: PaperPlaneTypes) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const pathRef = useRef<SVGPathElement>(null);
  const planeRef = useRef<SVGSVGElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  const onScroll = () => {
    const path = pathRef.current;
    const mainContainer = mainContainerRef.current;
    const svg = svgRef.current;

    if (!path || !mainContainer || !svg) return;
    
    if (path.style.display === "none") path.style.display = "inline";

    const pathLength = path.getTotalLength();
    const containerHeight = mainContainer.offsetHeight - (TOP_OFFSET / 2);

    svg.setAttribute("viewBox", `0 0 592 ${containerHeight}`);

    const scale = containerHeight / SVG_HEIGHT;
    const maxDrawLenght = pathLength * scale;
    const strokeDasharray = `${pathLength} ${pathLength}`;
    const scrollPercentage = getScrollPercentage(sectionRef);
    const drawLength = maxDrawLenght * scrollPercentage;
    const strokeDashoffset = pathLength - drawLength;
    
    if(scrollPercentage <= 1) {
      drawPath(path, strokeDashoffset, strokeDasharray);
      positionElements(drawLength);
    }
  };

  const drawPath = (path: SVGPathElement, strokeDashoffset: number, strokeDasharray: string) => {
    path.style.strokeDashoffset = `${strokeDashoffset}`;
    path.style.strokeDasharray = strokeDasharray;
  }

  const positionElements = (drawLength: number) => {
    const plane = planeRef.current;
    if(!plane) return;

    plane.style.offsetDistance = `${drawLength}px`;
  };

  const setInitialState = () => {
    const section = sectionRef.current;
    const path = pathRef.current;
    if (!section || !path) return;
    path.style.display = "none";
  };

  useEffect(() => {
    if (!window) return;

    window.addEventListener("scroll", onScroll);
    setInitialState();
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [onScroll]);
  
  return (
    <PathSection ref={sectionRef} {...props}>
      <svg ref={svgRef} xmlns="http://www.w3.org/2000/svg">
        <path
          ref={pathRef}
          fill="#FBF7F2"
          stroke="#001B71"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeDasharray="8 8"
          opacity="0.5"
          d={DEFAULT_PATH}
        />
        <LayerDashedPath 
          path={DEFAULT_PATH}
        />
      </svg>
      <PlaneIcon
        width={PLANE_WIDTH}
        height={PLANE_WIDTH}
        ref={planeRef}
        style={{
          position: 'absolute',
          top: '0px',
          left: '10px',
          transformBox: 'fill-box',
          offsetPath: `path('${DEFAULT_PATH}')`,
          offsetRotate: 'auto',
          opacity: 0.7,
        }}
      />
    </PathSection>
  );
};
