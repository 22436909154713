import { graphql } from '@gql';

export const getPageByUrlQueryDocument = graphql(/* GraphQL */ `
  query getPageByUrl($url: String!, $locale: String!) {
    all_vildar_page(fallback_locale: true, locale: $locale, where: { url: $url }) {
      __typename
      items {
        ...PageFragment
        ...ComponentsFragment
      }
    }
  }
`);

export const VildarPageQueryFragment = graphql(/* GraphQL */ `
  fragment VildarPageQueryFragment on VildarPage {
    ...PageFragment
    ...ComponentsFragment
  }
`);

export const ComponentsFragment = graphql(/* GraphQL */ `
  fragment ComponentsFragment on VildarPage {
    components {
      ...HeroBannerFragment
      ...ContentSectionFragment
      ...VildarCardSectionFragment
      ...VildarIframeSectionFragment
      ...VildarTabSectionFragment
      ...DocumentsSectionFragment
    }
  }
`);

export const PageFragment = graphql(/* GraphQL */ `
  fragment PageFragment on VildarPage {
    __typename
    title
    url
    hidden
    seo {
      description
      title
      imageConnection {
        __typename
        edges {
          node {
            __typename
            dimension {
              height
              width
            }
            system {
              uid
            }
            content_type
          }
        }
      }
    }
    translation_strings {
      groups
    }
    system {
      uid
      content_type_uid
      updated_at
    }
  }
`);
