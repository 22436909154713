import { ComponentType } from 'react';

import dynamic from 'next/dynamic';

export const componentMap = {
  VildarPageComponentsBanner: dynamic(() =>
    import('components/features/banner/banner').then((module) => module.Banner)
  ),
  VildarPageComponentsContentSection: dynamic(() =>
    import('components/features/content-section/content-section').then((module) => module.ContentSection)
  ),
  VildarPageComponentsCards: dynamic(() =>
    import('components/features/cards/card-section').then((module) => module.CardsSection)
  ),

  VildarPageComponentsNavigationMenu: dynamic(() =>
    import('components/features/navigation-menu/navigation-menu').then((module) => module.NavigationMenu)
  ),
  VildarPageComponentsFooter: dynamic(() =>
    import('components/features/footer/footer').then((module) => module.Footer)
  ),
  VildarPageComponentsTabsSection: dynamic(() =>
    import('components/features/tab-section/tab-section').then((module) => module.TabSection)
  ),
  VildarPageComponentsDocuments: dynamic(() =>
    import('components/features/documents/documents-section').then((module) => module.DocumentsSection)
  ),
  VildarPageComponentsForms: dynamic(() => import('components/features/iframe/iframe').then((module) => module.Iframe)),
} satisfies Record<string, ComponentType<any>>;
