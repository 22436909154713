import styled, { css } from 'styled-components';
import { mediaQueries } from 'styles/helpers';

export const PathSection = styled.section`
  display: none;
  position: absolute;
  top: 240px;
  left: 10%;
  width: min(592px, 90%);
  min-height: 280px;
  margin-left: auto;
  margin-right: auto;
  z-index: 0;

  ${mediaQueries(
    'lg',
    css`
      display: block;
    `
  )}
`;
