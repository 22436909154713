import { ComponentType, Suspense } from 'react';

import { ComponentsFragment } from '../page/page-gql';
import { ComponentsContainer, ComponentsWrapper } from './component-resolver.styles';
import { componentMap } from './mappings';
import { FragmentType, getFragmentData } from '@gql';

type Props = FragmentType<typeof ComponentsFragment>;

export const ComponentResolver = (props: Props) => {
  const { components } = getFragmentData(ComponentsFragment, props);
  if (!components) return null;
  else {
    return (
      <ComponentsContainer>
        {components.map((component, index) => {
          if (!component?.__typename) return null;

          const Component = componentMap[component!.__typename] as ComponentType;
          if (!Component) return null;
          return (
            <ComponentsWrapper key={`Wrapper-${component.__typename}-${index}`}>
              <Suspense fallback={null}>
                <Component
                  key={`${component!.__typename}-${index}`}
                  {...component}
                />
              </Suspense>
            </ComponentsWrapper>
          );
        })}
      </ComponentsContainer>
    );
  }
};
