'use client';

import { useRef } from 'react';

import { ComponentResolver } from '../component-resolver/component-resolver';
import { PaperPlane } from '../paper-plane/paper-plane';
import { ComponentsFragment, PageFragment } from './page-gql';
import { BreakpointHelper, Container } from '@aurora-components';
import { FragmentType, getFragmentData } from '@gql';

type Props = FragmentType<typeof PageFragment> & FragmentType<typeof ComponentsFragment>;

const Page = (props: Props) => {
  /* Resolve the fragment to a typed object */
  const mainContainerRef = useRef<HTMLDivElement>(null);
  const page = getFragmentData(PageFragment, props);

  if (!page) return null;

  const isMainPage = page.url === '/';

  return (
    <>
      <Container
        data-testid="page-container"
        $maxWidth="screen"
        $fullHeight
        $centered
        $pageContainer
        data-pageref={page.system!.uid}
        data-contenttype={page.system!.content_type_uid}
        ref={mainContainerRef}
      >
        <ComponentResolver {...props} />
        {isMainPage && (
          <PaperPlane
            data-testid="paper-plane"
            mainContainerRef={mainContainerRef}
          />
        )}
      </Container>
      <BreakpointHelper />
    </>
  );
};

export default Page;
